import {
    Checkbox,
    colors,
    ContentText,
    DatePicker,
    Heading,
    IconButton,
    IconChevronLeft,
    IconChevronRight,
    IconError,
    IconInfo,
    Link,
    Loader,
    Modal,
    Popover,
    spacing,
    spacingNumerical,
    Toggle,
    usePopover,
    useToggle,
} from '@fortum/elemental-ui';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import { FormattedNumber } from 'react-intl';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameMonth from 'date-fns/isSameMonth';
import parse from 'date-fns/parse';
import pl from 'date-fns/locale/pl';
import subYears from 'date-fns/subYears';
import subMonths from 'date-fns/subMonths';
import { AppDispatch, RootState } from 'src/providers/store';
import { Discount, RestrictionStatus } from 'src/api/model/Discount';
import { DateRange } from 'src/containers/ConsumptionPointDatesInput/ConsumptionPointDatesInput.types';
import { ConsumptionPointItem } from 'src/containers/SecondStep/SecondStep.types';
import { useDownloadFile } from 'src/utils/useDownloadFile';
import { getCSVReport } from 'src/api/requests';
import Notification from 'src/components/Notification/Notification';

import { fetchDiscountsMonthDetails } from './CalculationDetailsModal.thunk';
import { DownloadNotificationData, SelectedDiscountData } from './CalculationDetailsModal.types';

import styles from './CalculationDetailsModal.module.scss';

type CalculationDetailsModalProps = {
    clientName: string;
    opened: boolean;
    onClose: () => void;
    dateRangeArr: DateRange[];
    consumptionPoint: ConsumptionPointItem;
    discount: Discount;
    selectedDiscounts: SelectedDiscountData[];
    setSelectedDiscounts: (value: SelectedDiscountData[]) => void;
    skipAgreementRule: boolean;
};

registerLocale('pl', pl);

const CalculationDetailsModal: FunctionComponent<CalculationDetailsModalProps> = ({
    clientName,
    opened,
    onClose,
    dateRangeArr,
    consumptionPoint,
    discount,
    selectedDiscounts,
    setSelectedDiscounts,
    skipAgreementRule,
}: CalculationDetailsModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const [showComplaintDaysOnly, toggleComplaintDaysOnly] = useToggle(false);
    const endDate = dateRangeArr.length && dateRangeArr[0].endDate ? dateRangeArr[0].endDate : null;
    const { open, anchor, handleOpen, handleClose } = usePopover();
    const discountsMonthDetailsState = useSelector((state: RootState) => state.discountsMonthDetailsState);
    const [month, setMonth] = useState<Date>(endDate ? endDate : new Date());
    const [downloadStatus, setDownloadStatus] = useState<DownloadNotificationData>({
        opened: false,
        hideCloseButton: false,
        notificationType: 'loading',
        notificationText: '',
    });

    useEffect(() => {
        if (opened) {
            getDiscountsMonthDetails();
        }
    }, [opened]);

    useEffect(() => {
        if (opened) {
            getDiscountsMonthDetails();
        }
    }, [month]);

    const filteredMonthDetails = discountsMonthDetailsState.discountsMonthDetails
        ? discountsMonthDetailsState.discountsMonthDetails.dailyDetails.filter(
              (item) => discount.daily.findIndex((day) => day.date === item.date) !== -1,
          )
        : [];

    const discountsMonthDetails = !showComplaintDaysOnly
        ? discountsMonthDetailsState.discountsMonthDetails
            ? discountsMonthDetailsState.discountsMonthDetails.dailyDetails
            : []
        : filteredMonthDetails;

    const restrictionStatus = discountsMonthDetailsState.discountsMonthDetails
        ? discountsMonthDetailsState.discountsMonthDetails.restrictionStatus
        : null;

    const preDownloading = () => {
        setDownloadStatus({
            opened: true,
            hideCloseButton: true,
            notificationType: 'loading',
            notificationText: `Pobieranie pełnego raportu dla ${consumptionPoint.name}`,
            notificationRetryLink: undefined,
        });
    };
    const postDownloading = () => {
        setDownloadStatus({
            opened: true,
            hideCloseButton: false,
            notificationType: 'success',
            notificationText: `Pełen raport pobrany pomyślnie`,
            notificationRetryLink: undefined,
        });
    };

    const onErrorDownloadFile = () => {
        setDownloadStatus({
            opened: true,
            hideCloseButton: false,
            notificationType: 'warning',
            notificationText: `Nie udało się pobrać raportu dla ${consumptionPoint.name}.`,
            notificationRetryLink: download,
        });
    };

    const setClosed = () => {
        setDownloadStatus({
            opened: false,
            hideCloseButton: false,
            notificationType: 'loading',
            notificationText: '',
        });
    };

    const getFileName = () => {
        return `Bonifikata_${clientName.replaceAll(' ', '_').replaceAll('.', '_')}_${format(
            new Date(),
            'ddMMyyyy',
        )}.xlsx`;
    };

    const { download } = useDownloadFile({
        apiDefinition: () =>
            getCSVReport(
                consumptionPoint.substationValue,
                consumptionPoint.value,
                selectedDiscounts.reduce((acc, item) => acc + ',' + item.date, ''),
                format(month, 'yyyy-MM-dd'),
            ),
        preDownloading,
        postDownloading,
        onError: onErrorDownloadFile,
        getFileName,
    });

    const isDateInSelected = (date: string) => {
        return selectedDiscounts.findIndex((day) => day.date === date) !== -1;
    };

    const getDiscountsMonthDetails = () => {
        dispatch(
            fetchDiscountsMonthDetails({
                substationId: consumptionPoint.substationValue,
                consumptionPointId: consumptionPoint.value,
                yearMonth: format(month, 'yyyy-MM'),
            }),
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, date: string, amount: number) => {
        if (e.target.checked) {
            setSelectedDiscounts([...selectedDiscounts, { date, amount, checked: true }]);
        } else {
            setSelectedDiscounts(selectedDiscounts.filter((s) => s.date !== date));
        }
    };

    const handleCloseModal = () => {
        onClose();
    };

    const renderErrorCell = () => (
        <td className={styles.error}>
            <IconError color={'#BF4F2A'} size={24} />
            <span>Brak danych</span>
        </td>
    );

    return (
        <Modal className={styles.modal} opened={opened} onClose={handleCloseModal}>
            <Heading
                style={{
                    marginBottom: spacingNumerical.xxxs,
                }}
                lineHeight={1.2}
                level={3}
                styledAs={5}
                textAlign="center"
            >
                Szczegóły wyniku kalkulacji
            </Heading>
            <ContentText style={{ marginBottom: spacingNumerical.xxxs }} size="xl" lineHeight={1.2} textAlign="center">
                {consumptionPoint.name} ({consumptionPoint.value})
            </ContentText>
            {discountsMonthDetailsState.loading === 'pending' && <Loader />}
            {discountsMonthDetailsState.loading === 'succeeded' && (
                <>
                    <div className={styles.topControl}>
                        <div className={styles.topLeft}>
                            <div className={styles.dateSelect}>
                                <IconButton
                                    status="plain"
                                    icon={<IconChevronLeft />}
                                    size="40px"
                                    aria-label="Wybierz wczesniejszy miesiąc"
                                    disabled={
                                        isSameMonth(month, subYears(new Date(), 3)) ||
                                        isBefore(month, subYears(new Date(), 3))
                                    }
                                    onClick={() => setMonth(subMonths(month, 1))}
                                />
                                <DatePicker
                                    borderStyle="bottomOnly"
                                    dateFormat="MMM yyyy"
                                    label=""
                                    locale="pl"
                                    maxDate={new Date()}
                                    minDate={subYears(new Date(), 3)}
                                    name="month"
                                    onChange={(date: Date | null) => {
                                        date && setMonth(date);
                                    }}
                                    selected={month}
                                    showFullMonthYearPicker
                                    showMonthYearPicker
                                    style={{ width: 185 }}
                                />
                                <IconButton
                                    status="plain"
                                    icon={<IconChevronRight />}
                                    size="40px"
                                    aria-label="Wybierz późniejszy miesiąc"
                                    disabled={isSameMonth(month, new Date()) || isAfter(month, new Date())}
                                    onClick={() => setMonth(addMonths(month, 1))}
                                />
                            </div>
                            <Toggle
                                checked={showComplaintDaysOnly}
                                name="showComplaintDaysOnly"
                                label="Pokaż dni tylko z reklamacji"
                                onChange={toggleComplaintDaysOnly}
                                value="off"
                            />
                        </div>
                        <div className={styles.topRight}>
                            <Link className={styles.firstLink} onClick={download}>
                                Pobierz pełen raport
                            </Link>
                            {/*<Link href="#">Zobacz wszystkie parametry węzła</Link>*/}
                        </div>
                    </div>
                    {downloadStatus.opened && (
                        <Notification
                            backgroundColor="#f2f2f2"
                            hideCloseButton={downloadStatus.hideCloseButton}
                            notificationType={downloadStatus.notificationType}
                            notificationText={downloadStatus.notificationText}
                            notificationRetryLink={downloadStatus.notificationRetryLink}
                            setClosed={setClosed}
                        />
                    )}
                    {restrictionStatus !== RestrictionStatus.NOT_RESTRICTED && !skipAgreementRule && (
                        <Notification
                            backgroundColor="#f7f7f7"
                            notificationType="info"
                            notificationText={
                                restrictionStatus === RestrictionStatus.RESTRICTED
                                    ? 'Warunek umowy dotyczący długości lub ilości dni z ograniczeniami nie został spełniony. Bonifikata nie należy się, gdy nieplanowana przerwa trwa poniżej 24 godzin lub ograniczenie trwa krócej niż 48 godzin, a ich łączna ilość nie przekracza dwóch w danym okresie rozliczeniowym.'
                                    : 'Warunek umowy dotyczący długości lub ilości dni z ograniczeniami nie mógł być sprawdzony.'
                            }
                            hideCloseButton={true}
                        />
                    )}
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={`${styles.first} ${styles.top}`}></th>
                                    <th className={`${styles.colSpan} ${styles.top}`} colSpan={7}>
                                        Parametry
                                    </th>
                                    <th className={styles.top}></th>
                                    <th className={styles.top}></th>
                                    <th className={`${styles.last} ${styles.top}`}></th>
                                </tr>
                                <tr>
                                    <th className={`${styles.first} ${styles.middle}`}>Data</th>
                                    <th className={styles.middle}>Przepływ i przepływ obl.</th>
                                    <th className={styles.middle}>Temp. zewnętrzna</th>
                                    <th className={styles.middle}>Moc zam. i moc zam. obl.</th>
                                    <th className={styles.middle}>
                                        <div className={styles.withTooltip}>
                                            <span>Moc dost.</span>
                                            <IconInfo
                                                onMouseEnter={handleOpen}
                                                onFocus={handleOpen}
                                                onBlur={handleClose}
                                                onMouseLeave={handleClose}
                                                size={24}
                                            />
                                            <Popover opened={open} anchor={anchor} anchorPos="bottom">
                                                <ContentText size={14} p={spacing.xs} color={colors.snowWhite}>
                                                    Procent mocy [%] - porównanie mocy dostarczonej
                                                    <br />
                                                    do mocy zamówionej na daną temp. zewnętrzną
                                                </ContentText>
                                            </Popover>
                                        </div>
                                    </th>
                                    <th className={styles.middle}>Temp. zas. sieciowa i zakresy</th>
                                    <th className={styles.middle}>Temp. zas. CO i zakresy</th>
                                    <th className={styles.middle}>Temp. zas. CWU i zakresy</th>
                                    <th className={styles.middle}>Ograniczony dzień</th>
                                    <th className={styles.middle}>Wyliczona należność</th>
                                    <th className={`${styles.last} ${styles.middle}`}>Uznanie bonifikaty</th>
                                </tr>
                                <tr>
                                    <th className={styles.first}></th>
                                    <th className={styles.units}>
                                        [m<sup>3</sup>/h]
                                    </th>
                                    <th className={styles.units}>
                                        [<sup>o</sup>C]
                                    </th>
                                    <th className={styles.units}>[kW] / [%]</th>
                                    <th className={styles.units}>[kW] / [%]</th>
                                    <th className={styles.units}>
                                        [<sup>o</sup>C]
                                    </th>
                                    <th className={styles.units}>
                                        [<sup>o</sup>C]
                                    </th>
                                    <th className={styles.units}>
                                        [<sup>o</sup>C]
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th className={styles.last}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {discountsMonthDetails.map((row) => (
                                    <tr key={row.date}>
                                        <td className={styles.first}>
                                            {format(parse(row.date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy')}
                                        </td>

                                        {row.flow === null || row.calculatedFlow === null ? (
                                            renderErrorCell()
                                        ) : (
                                            <td>
                                                <span>
                                                    <FormattedNumber
                                                        value={row.flow}
                                                        minimumFractionDigits={1}
                                                        maximumFractionDigits={1}
                                                    />
                                                </span>
                                                <span className={styles.small}>
                                                    <FormattedNumber
                                                        value={row.calculatedFlow}
                                                        minimumFractionDigits={1}
                                                        maximumFractionDigits={1}
                                                    />
                                                </span>
                                            </td>
                                        )}
                                        {row.outdoorTemperature === null ? (
                                            renderErrorCell()
                                        ) : (
                                            <td>
                                                <FormattedNumber
                                                    value={row.outdoorTemperature}
                                                    minimumFractionDigits={1}
                                                    maximumFractionDigits={1}
                                                />
                                            </td>
                                        )}
                                        {row.orderedPower === null ? (
                                            renderErrorCell()
                                        ) : (
                                            <td>
                                                <span>
                                                    <FormattedNumber
                                                        value={row.orderedPower}
                                                        minimumFractionDigits={2}
                                                        maximumFractionDigits={2}
                                                    />
                                                </span>
                                                <span className={styles.small}>
                                                    <FormattedNumber
                                                        value={row.calculatedOrderedPower}
                                                        minimumFractionDigits={2}
                                                        maximumFractionDigits={2}
                                                    />
                                                </span>
                                            </td>
                                        )}
                                        {row.deliveredPower === null || row.powerRatio === null ? (
                                            renderErrorCell()
                                        ) : (
                                            <td>
                                                <span>
                                                    <FormattedNumber
                                                        value={row.deliveredPower}
                                                        minimumFractionDigits={1}
                                                        maximumFractionDigits={1}
                                                    />
                                                </span>
                                                <span className={styles.small}>
                                                    <FormattedNumber
                                                        value={row.powerRatio * 100}
                                                        minimumFractionDigits={1}
                                                        maximumFractionDigits={1}
                                                    />
                                                </span>
                                            </td>
                                        )}
                                        {row.mainChSourceTemperature === null ||
                                        row.mainChMinTemperature === null ||
                                        row.mainChMaxTemperature === null ? (
                                            renderErrorCell()
                                        ) : (
                                            <td>
                                                <span>
                                                    <FormattedNumber
                                                        value={row.mainChSourceTemperature}
                                                        minimumFractionDigits={1}
                                                        maximumFractionDigits={1}
                                                    />
                                                </span>
                                                <span className={styles.small}>
                                                    <FormattedNumber
                                                        value={row.mainChMinTemperature}
                                                        minimumFractionDigits={0}
                                                        maximumFractionDigits={0}
                                                    />
                                                    -
                                                    <FormattedNumber
                                                        value={row.mainChMaxTemperature}
                                                        minimumFractionDigits={0}
                                                        maximumFractionDigits={0}
                                                    />
                                                </span>
                                            </td>
                                        )}
                                        {row.chSourceTemperature === null ||
                                        row.chMinTemperature === null ||
                                        row.chMaxTemperature === null ? (
                                            renderErrorCell()
                                        ) : (
                                            <td>
                                                <span>
                                                    <FormattedNumber
                                                        value={row.chSourceTemperature}
                                                        minimumFractionDigits={1}
                                                        maximumFractionDigits={1}
                                                    />
                                                </span>
                                                <span className={styles.small}>
                                                    <FormattedNumber
                                                        value={row.chMinTemperature}
                                                        minimumFractionDigits={0}
                                                        maximumFractionDigits={0}
                                                    />
                                                    -
                                                    <FormattedNumber
                                                        value={row.chMaxTemperature}
                                                        minimumFractionDigits={0}
                                                        maximumFractionDigits={0}
                                                    />
                                                </span>
                                            </td>
                                        )}
                                        {row.hwSourceTemperature === null ||
                                        row.hwMinTemperature === null ||
                                        row.hwMaxTemperature === null ? (
                                            renderErrorCell()
                                        ) : (
                                            <td>
                                                <span>
                                                    <FormattedNumber
                                                        value={row.hwSourceTemperature}
                                                        minimumFractionDigits={1}
                                                        maximumFractionDigits={1}
                                                    />
                                                </span>
                                                <span className={styles.small}>
                                                    <FormattedNumber
                                                        value={row.hwMinTemperature}
                                                        minimumFractionDigits={0}
                                                        maximumFractionDigits={0}
                                                    />
                                                    -
                                                    <FormattedNumber
                                                        value={row.hwMaxTemperature}
                                                        minimumFractionDigits={0}
                                                        maximumFractionDigits={0}
                                                    />
                                                </span>
                                            </td>
                                        )}
                                        <td className={`${row.heatingSupplyRestriction ? styles.red : ''}`}>
                                            {row.heatingSupplyRestriction ? '1' : '0'}
                                        </td>
                                        <td>
                                            <FormattedNumber
                                                value={row.discountValue}
                                                style="currency"
                                                currency="PLN"
                                            />
                                        </td>
                                        <td className={`${styles.last} ${styles.checkbox}`}>
                                            <span className={styles.checkboxContainer}>
                                                <Checkbox
                                                    disabled={
                                                        !row.heatingSupplyRestriction ||
                                                        !row.discountValue ||
                                                        (restrictionStatus !== RestrictionStatus.NOT_RESTRICTED &&
                                                            !skipAgreementRule)
                                                    }
                                                    checked={isDateInSelected(row.date)}
                                                    onChange={(e) => handleChange(e, row.date, row.discountValue)}
                                                />
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            {discountsMonthDetailsState.loading === 'failed' && (
                <>
                    <IconError color={'#BF4F2A'} size={40} />
                    <ContentText color={'#41414A'}>Błąd wyświetlania</ContentText>
                    <ContentText color={'#777'} size={16}>
                        Nie udało się wczytać wyników &nbsp;
                        <Link size="s" onClick={() => getDiscountsMonthDetails()}>
                            Spróbuj ponownie
                        </Link>
                    </ContentText>
                </>
            )}
        </Modal>
    );
};

export default CalculationDetailsModal;
